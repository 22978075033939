<template>
  <div>
    <div class="titleText primary--text py-4">
      <span> Welcome, please sign in</span>
    </div>
    <v-form lazy-validation ref="loginForm">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="email"
            label="Email"
            :rules="[rules.required, rules.email]"
            height="42px"
            type="email"
            @keyup.enter="submit"
            :disabled="loading"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="password"
            label="Password"
            height="42px"
            :rules="[rules.required]"
            :type="!showPassword ? 'password' : 'text'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            @keyup.enter="submit"
            :disabled="loading"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            dark
            class="primary buttonClass text-capitalize"
            height="48px"
            @click="submit"
            :loading="loading"
          >
            <span>Sign in</span>
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center py-4 forgetpassword orange--text">
          <span class="pointer" @click="goforget"
            >Forgot your password?</span
          ></v-col
        >
      </v-row>
    </v-form>
  </div>
</template>
<script>
import RULES from "@/common/fieldRules";
import authToken from "@/common/authToken";

export default {
  name: "login",
  data() {
    return {
      rules: RULES,
      showPassword: false,
      setPassword: false,
      password: "",
      email: "",
      loading: false,
    };
  },
  methods: {
    submit() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        const successHandler = (res) => {
          authToken.setToken(
            res.data.data.access_token,
            res.data.data.refresh_token
          );
          this.loading = false;
          this.$router.push({ name: "dashboard" });
        };
        const failureHandler = () => {
          this.loading = false;
        };
        let formData = {};
        formData["email"] = this.email;
        formData["password"] = this.password;
        formData["platform"] = "web";

        return this.$request(this.$keys.POST, this.$urls.auth.login, {
          data: formData,
          onSuccess: successHandler,
          onFinally: failureHandler,
          isTokenRequired: false
        });
      }
    },
    goforget() {
      this.$router.push({ name: "forgetPassword" });
    },
  },
};
</script>
<style scoped>
.buttonClass {
  width: 165px;
  border-radius: 5px;
}
.forgetpassword {
  font-weight: 400;
  font-size: 13px;
  line-height: 48px;
}
</style>
